<template>
  <div>
    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(name)="data">
          <b-icon
            style="width: 9px"
            icon="circle-fill"
            :class="data.item.status ? 'text-success' : 'text-danger'"
          />
          {{ data.item.name }}
        </template>

        <template #cell(actions)="data">
          <div class="form-row align-items-center justify-content-center">
            <div class="col-md-6 text-center">
              <feather-icon
                :id="`customer-${data.item.uuid}-delete-icon`"
                icon="TrashIcon"
                class="cursor-pointer cursor text-danger"
                size="16"
                @click="confirmDelete(data.item.uuid)"
              />
            </div>
            <div class="col-md-6 text-center">
              <feather-icon
                :id="`customer-${data.item.uuid}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
                @click="modalEdit(data.item.uuid)"
              />
            </div>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />

      <b-modal
        id="modal-create-course-type"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
      >
        <b-form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-8 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.name"
                  :class="{ 'is-invalid': $v.item.name.$error }"
                />
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Status
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  :searchable="false"
                  item-text="title"
                  item-value="code"
                  placeholder="Status"
                  v-model="item.status"
                  :options="optionsStatus"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                />
              </div>
            </div>
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-course-type')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  data() {
    return {
      filters: {
        currentPage: 1,
        perPage: 100,
      },
      uuid: null,
      textSubmit: "",
      submited: false,
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
        status: "",
      },
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      status: {
        required,
      },
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-course-type", () => {
      this.clearForm();
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Tipo de curso";
      this.$bvModal.show("modal-create-course-type");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-course-type");
  },
  methods: {
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();
      this.$store.dispatch("Course/fetchShowType", uuid).then((res) => {
        this.item.name = res.name;
        this.item.status = res.status;
        this.uuid = res.uuid;
        this.$bvModal.show("modal-create-course-type");
      });
    },
    submitCreate() {
      this.$v.item.$touch();
      this.submited = true;

      const data = {
        name: this.item.name,
        status: this.item.status.code
      }

      if (!this.$v.item.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Course/fetchStoreType", data)
            .then(() => {
              this.notifyDefault("success");
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-course-type");
                this.clearForm();
                this.$v.item.reset()
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Course/fetchUpdateType", { uuid: this.uuid, data: data })
            .then(() => {
              this.getData();

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-course-type");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    clearForm() {
      this.item = {
        name: "",
        status: "",
      };
    },
    async getData() {
      this.$store
        .dispatch("Course/fetchAllTypes", this.filters)
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.filters.currentPage = res.meta.current_page;
          }
        });
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Course/fetchDeleteType", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
};
</script>
